.Card {
    margin: 0.25em 0 0.25em 1%;
    padding: 0.25em;
    display: inline-block;
    min-width: 47%
}

.Card.Card--white {
    background-color: #ffffeb;
}

.Card.Card--blue {
    background-color: #d4edff;
}

.Card.Card--black {
    background-color: #e5dff2;
}

.Card.Card--red {
    background-color: #ffd8d4;
}

.Card.Card--green {
    background-color: #e9ffd4;
}

.Card.Card--multicolored {
    background-color: #fcf8a9;
}

.Card.Card--artifact {
    background-color: #dcdae0;
}

.Card.Card--colorless {
    background-color: #dcdae0;
}

.Card.Card--land {
    background-color: #ffdac4;
}

.Card.Card--unknown {
    background-color: white;
}
