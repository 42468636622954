.header {
  text-align: center;
  background-color: sienna;
  color: antiquewhite;
  font-family: "Beleren Bold", Helvetica, sans-serif;
  padding: 0.2em 0 0.1em 0;
  margin: 0;
}

.actions {
  margin: 0.25em;
}

.actions .switchAction { 
  font-size: 1.5em;
}

select.deckSelector {
  font-size: 1.25em;
  padding: 0.25em;
}

.actions  select.deckSelector {
  width: 90%;
}

.sourceDeck {
  width: 100%;
  padding: 0.25em;
}